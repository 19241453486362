import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { ROUTES } from "../enum/routes";
import AdminLogin from "../pages/auth/AdminLogin/AdminLogin";
import AdminDashboard from "../pages/AdminDashboard/AdminDashboard";
import WelcomeTab from "../components/WelcomeTab/WelcomeTab";
import NewsTab from "../pages/News/NewsTab";
import BlogTab from "../pages/BlogTab/BlogTab";
import BlogAddForm from "../pages/BlogTab/AddBlogs/BlogModal";
import StoryTab from "../pages/Stories/StoryTab";
import StoryAddForm from "../pages/Stories/AddStory/StoryAddForm";
import EventsTab from "../pages/Events/EventsTab";
import EventAddForm from "../pages/Events/AddEvent/EventAddForm";
import ResearchTab from "../pages/Research/ResearchTab";
import ResearchAdd from "../pages/Research/AddResearch/ResearchAdd";
import NewsletterTab from "../pages/Newsletter/NewsletterTab";
import NewsletterForm from "../pages/Newsletter/AddNewsletter/NewsletterForm";
import SubscribedUsers from "../pages/Newsletter/SubscribedUsers/SubscribedUsers";
import ContactTab from "../pages/Contact Form/ContactTab";
import NewsAddForm from "../pages/News/AddNews/AddNews";
import CourseTab from "../pages/CourseTab/CourseTab";
import CourseAddForm from "../pages/CourseTab/AddCourse/CourseModal";
import CampusTab from "../pages/CampusTab/CampusTab";
import CampusAddForm from "../pages/CampusTab/AddCampus/CampusModal";
import TalkTab from "../pages/TalkTab/TalkTab";
import TalkAddForm from "../pages/TalkTab/AddTalk/TalkModal";

export const appRouter = createBrowserRouter([
  {
    path: ROUTES.ADMINLOGIN,
    element: <AdminLogin />,
  },
  {
    path: ROUTES.ADMINDASHBOARD,
    element: <AdminDashboard />,
    children: [
      {
        path: "news",
        element: <NewsTab />,
      },
      {
        path: "news/add-news",
        element: <NewsAddForm />,
      },
      {
        path: "/news/edit/:id",
        element: <NewsAddForm />,
      },
      {
        path: "",
        element: <WelcomeTab />,
      },
      {
        path: "blogs",
        element: <BlogTab />,
      },
      {
        path: "blogs/add-blog",
        element: <BlogAddForm />,
      },
      {
        path: "/blogs/edit/:id",
        element: <BlogAddForm />,
      },
      {
        path: "course",
        element: <CourseTab />,
      },
      {
        path: "course/add-course",
        element: <CourseAddForm />,
      },
      {
        path: "/course/edit/:id",
        element: <CourseAddForm />,
      },
      // {
      //   path: "campus",
      //   element: <CampusTab />,
      // },
      // {
      //   path: "campus/add-campus",
      //   element: <CampusAddForm />,
      // },
      // {
      //   path: "/campus/edit/:id",
      //   element: <CampusAddForm />,
      // },
      {
        path: "talk",
        element: <TalkTab />,
      },
      {
        path: "talk/add-talk",
        element: <TalkAddForm />,
      },
      {
        path: "/talk/edit/:id",
        element: <TalkAddForm />,
      },
      // {
      //   path: "/stories",
      //   element: <StoryTab />,
      // },
      // {
      //   path: "/stories/add-story",
      //   element: <StoryAddForm />,
      // },
      // {
      //   path: "/stories/edit/:id",
      //   element: <StoryAddForm />,
      // },
      {
        path: "/events",
        element: <EventsTab />,
      },
      {
        path: "/events/add-event",
        element: <EventAddForm />,
      },
      {
        path: "/events/edit/:id",
        element: <EventAddForm />,
      },
      {
        path: "/research",
        element: <ResearchTab />,
      },
      {
        path: "/research/add-research",
        element: <ResearchAdd />,
      },
      {
        path: "/research/edit/:id",
        element: <ResearchAdd />,
      },
      {
        path: "/newsletter",
        element: <NewsletterTab />,
      },
      {
        path: "/newsletter/add-newsletter",
        element: <NewsletterForm />,
      },
      {
        path: "/newsletter/edit/:id",
        element: <NewsletterForm />,
      },
      {
        path: "/newsletter/view-subscribed-users",
        element: <SubscribedUsers />,
      },
      {
        path: "/contacts",
        element: <ContactTab />,
      },
    ],
  },
]);
